<template>
  <div
    class="page-container w-screen h-vh-9/10 relative flex flex-wrap justify-center"
  >
    <loader v-if="isLoading"/>
    <div class="flex justify-center flex-wrap max-h-9/10 max-w-screen overflow-hidden">
      <div
        class="flex flex-wrap w-full justify-around items-center h-full"
      >
        <div class="flex flex-wrap w-full">
          <div
            class="flex flex-col justify-center items-end flex-1 font-bold text-left px-5 text-primary text-xl sm:text-2xl"
          >
            <div class="sm:hidden my-5 w-full text-center text-2xl uppercase">
              Contáctanos
            </div>
            <div class="w-full">
              <div
                class="text-right flex flex-wrap justify-start sm:justify-end mb-3"
                data-aos="fade-right"
                data-aos-delay="600"
                data-aos-anchor-placement="top-bottom"
              >
                <label for="name" class="leading-5">NOMBRE</label>
                <div class="w-full">
                  <input
                    v-model="nameContact"
                    type="text"
                    class="w-full text-primary px-5 text-2xl border border-primary border-r-0 border-l-0 border-t-0"
                  />
                </div>
              </div>
              <div
                class="text-right flex flex-wrap justify-start sm:justify-end mb-3"
                data-aos="fade-right"
                data-aos-delay="800"
                data-aos-anchor-placement="top-bottom"
              >
                <label for="name" class="leading-5">CORREO</label>
                <div class="w-full">
                  <input
                    v-model="emailContact"
                    type="text"
                    class="w-full text-primary px-5 text-2xl border border-primary border-r-0 border-l-0 border-t-0"
                  />
                </div>
              </div>
              <div
                class="text-right flex flex-wrap justify-start sm:justify-end mb-3"
                data-aos="fade-right"
                data-aos-delay="1000"
                data-aos-anchor-placement="top-bottom"
              >
                <label for="name" class="leading-5">ASUNTO</label>
                <div class="w-full">
                  <textarea
                    v-model="asuntoContact"
                    type="text"
                    rows="5"
                    class="w-full text-primary px-5 text-2xl border border-primary border-r-0 border-l-0 border-t-0"
                  />
                </div>
              </div>
              <div
                class="text-right flex flex-wrap justify-start sm:justify-end mb-3"
                data-aos="fade-right"
                data-aos-delay="1200"
                data-aos-anchor-placement="top-bottom"
              >
                <button
                  @click="sendEmailContact()"
                  class="rounded-md focus:outline-none flex justify-center items-center text-2xl text-center border-2 border-primary btn btn-special-1 w-full sm:w-auto px-10 hover:text-white"
                >
                  Enviar correo
                  <font-awesome-icon
                    :icon="['fas', 'arrow-right']"
                    class="fa-1x absolute right-4 animate-goto"
                  />
                </button>
              </div>
            </div>
          </div>

          <div
            data-aos="zoom-in"
            data-aos-delay="800"
            data-aos-anchor-placement="top-bottom"
          >
            <div
              style="min-width:1px"
              class="border-2 border-primary h-full"
            ></div>
          </div>
          <div
            class="w-full sm:w-6/12 flex flex-col items-center sm:justify-start sm:items-start sm:flex-1 font-bold text-left px-5 text-lg sm:text-xl relative py-10"
          >
            <div
              class="text-secondary"
              data-aos="fade-left"
              data-aos-delay="600"
              data-aos-anchor-placement="top-bottom"
            >
              Tel. <span class="text-primary">(55) 55368650</span>
            </div>
            <div
              class="text-secondary"
              data-aos="fade-left"
              data-aos-delay="700"
              data-aos-anchor-placement="top-bottom"
            >
              Correo: <span class="text-primary">notificaciones@sabar.mx</span>
            </div>
            <div
              class="text-secondary"
              data-aos="fade-left"
              data-aos-delay="800"
              data-aos-anchor-placement="top-bottom"
            >
              Dirección:
              <p class="text-primary sm:text-justify text-center">
                <!-- Providencia #807, Piso 6, <br /> -->
                Av. Coyoacán, Colonia Del valle Sur, <br />
                Delegación Benito Juárez, <br />
                C.P. 031000, CDMX
              </p>
            </div>
            <img
              :src="require('../assets/img/americanov2_rs.png')"
              class="relative sm:absolute sm:-left-20 sm:-bottom-1/2 left-0 w-5/10"
              data-aos="top-bottom"
              data-aos-delay="500"
              data-aos-anchor-placement="top-bottom"
            />
          </div>
        </div>
        <div class="w-full"></div>
      </div>
    </div>
  </div>
</template>





<script>
export default {
  name: "ContactUsPage",
  data: function() {
    return {
      isLoading:false,
      nameContact:"",
      emailContact:"",
      asuntoContact:"",
    }
  },
  components: {},
  computed: {},
  mounted() {
    this.$store.commit("updateBgApp", "");
  },
  destroyed() {},
  methods: {

    async sendEmailContact() {
      this.isLoading = true
      let self = this;

      if(this.nameContact == "" || this.emailContact == "" || this.asuntoContact == ""){
        self.$notify({
          group: "foo",
          title: "Envió de Datos Personales Fallido",
          text: `<b>Rellena todos los campos correspondientes</b>`,
          type: "error",
          duration: 3000,
        });
      } else {
        const path = this.$APIServices.endpoint + this.$APIServices.send_email_contact + `${this.nameContact}/${this.emailContact}/${this.asuntoContact}/`
        // console.log(path)
        await this.$http.post(path)
        .then(() => {
            // console.log(response)
        })
      .catch((e)=> {
        // console.log(e)
        throw e;
      }).finally(()=> {
          self.$notify({
            group: "foo",
            title: "Envió de Datos Personales Exitoso",
            text: `<b>Muy Pronto nos Contactaremos Contigo</b>`,
            type: "success",
            duration: 3000,
          });
          this.nameContact = ""
          this.emailContact = ""
          this.asuntoContact = ""
          this.isLoading = false
        })
      }
  },


  }
};
</script>
<style >
input,
textarea {
  outline: none;
  background: rgba(182, 162, 223, 0.3);
}
</style>